import cn from "classnames";
import {addMinutes, formatDistanceToNow} from "date-fns";
import {Dispatch, SetStateAction, useEffect, useState} from "react";
import {FormatDuration, mapContent, twColors} from "../utils";
import EditPhrase from "./EditPhrase";
import {Avatar, Box, CircularProgress, makeStyles} from "@material-ui/core";
import theme from "../config/theme";
import EditIcon from "@material-ui/icons/Edit";
import PersonIcon from "@material-ui/icons/Person";
import clsx from 'clsx';
import {TranscriptStatus} from "../services";
import {useStore} from "../store";

const useStyles = makeStyles({
  containerTitle: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'start',
  },
  button: {
    textDecoration: 'none',
    border: 'none',
    cursor: 'pointer',
    background: 'transparent',
    padding: '0',
    position: 'relative',
    top: '6px',
  },
  speakerBtn: {
    textDecoration: 'none',
    border: 'none',
    cursor: 'pointer',
    fontWeight: 'bold',
    '&:hover': {
      textDecoration: 'underline',
      color: theme.palette.primary.main,
    },
    background: 'transparent',
    padding: '0',
  },
  durationBtn: {
    textDecoration: 'none',
    border: 'none',
    color: theme.palette.grey[600],
    fontWeight: 'normal',
    fontStyle: 'italic',
    fontSize: '0.75rem',
    cursor: 'pointer',
    '&:hover': {
      textDecoration: 'underline',
    },
    background: "transparent",
    padding: '0',
    marginTop: '8px',
  },
  customIcon: {
    textDecoration: 'none',
    border: 'none',
    background: 'transparent',
    color: theme.palette.grey[700],
    fontSize: '0.875rem',
    cursor: 'pointer',
    '&:hover': {
      color: theme.palette.info.main,
    },
  },
  flex: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    gap: '8px',
  },
  editedPhrase: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
    marginTop: '6px',
    backgroundColor: '#f5f6f8',
    borderRadius: '8px',
    padding: '14px',
  },
  noneSpeaker: {
    width: '2.5rem',
    height: '2.5rem',
  },
  inline: {
    display: 'inline',
  },
  contentContainer: {
    marginLeft: '1rem',
    marginTop: '0.5rem',
    width: '100%',
  },
  avatar: {
    position: 'relative',
    top: '8px',
    width: theme.spacing(5),
    height: theme.spacing(5),
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: '50%',
    border: `2px solid ${theme.palette.grey[500]}`,
  },
  saving: {
    fontSize: '10px',
    color: theme.palette.grey[600],
    fontStyle: 'italic',
    position: 'relative',
    top: '3px'
  },
  confidence: {
    fontSize: theme.typography.pxToRem(12),
    fontStyle: 'italic',
    paddingTop: theme.spacing(0.5),
    fontWeight: 'normal',
    color: 'gray',
    float: 'right',
    mr: 4
  },
  confidenceBackground: {
    borderRadius: '9999px',
    height: theme.spacing(2),
    width: theme.spacing(6),
    padding: theme.spacing(1),
    color: theme.palette.common.white,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  circle: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    height: '100%',
    borderRadius: '50%',
    backgroundColor: (props: any) => props.backgroundColor,
    color: '#ffffff',
    fontWeight: 'bold',
  },
  green: {
    backgroundColor: theme.palette.success.main,
  },
  yellow: {
    backgroundColor: theme.palette.warning.main,
  },
  red: {
    backgroundColor: theme.palette.error.main,
  },
  saved: {
    transition: "all 3s ease-in",
    color: "gray",
    fontSize: 13
  }
});

const RecognizedPhrase: React.FC<{
  previousPhrase?: any;
  phrase: any;
  currentTime: number;
  onPhraseEdit: (status: TranscriptStatus, content: string) => Promise<void>;
  onEditMode: Dispatch<SetStateAction<boolean>>;
  isUpdating: boolean;
  position: number;
  goToTime: (time: number) => void;
}> = ({
  previousPhrase,
  phrase,
  currentTime,
  onPhraseEdit,
  onEditMode,
  isUpdating,
  position,
  goToTime
}) => {
  const [showEdit, setShowEdit] = useState(false);
  const [saving, setSaving] = useState(false);
  const [showEditPhrase, setShowEditPhrase] = useState(false);
  const [showSaved, setShowSaved] = useState(false);
  const formattedDuration = FormatDuration(phrase.offsetSec);
  const formattedDurationEnd = FormatDuration(
    phrase.offsetSec + phrase.durationSec
  );
  const backgroundColor = twColors[phrase.speaker % twColors.length];
  const classes = useStyles({backgroundColor});
  const {transcribeStore} = useStore();
  const {transcriptData} = transcribeStore;

  const speaker = phrase.speakerName
    ? phrase.speakerName
    : `Speaker ${phrase.speaker}`;

  const previousSpeakerName = previousPhrase
    ? previousPhrase.speakerName
      ? previousPhrase.speakerName
      : `Speaker ${previousPhrase.speaker}`
    : null;
  
  useEffect(() => {
    onEditMode(showEditPhrase);
  }, [showEditPhrase])

  return (
    <Box id={`phraseid-${phrase.id}`} sx={{display: 'flex'}}>
      {previousSpeakerName !== speaker ? (
        <div className={classes.avatar}>
          <Avatar>
            <div className={classes.circle}>
              {phrase.speaker}
            </div>
          </Avatar>
        </div>
      ) : (
        <div>
          <div className={classes.noneSpeaker}>&nbsp;</div>
        </div>
      )}
      <div className={classes.contentContainer}>
        <div className={classes.containerTitle}>
          <>
            {
              previousSpeakerName !== speaker &&
              (
                <button
                  className={classes.speakerBtn}
                  title={
                    phrase.lastEditor?.fullName
                      ? `Edited by ${phrase.lastEditor?.fullName}`
                      : "tag speaker"
                  }
                  onClick={() => setShowEdit(!showEdit)}
                >
                  {speaker}
                </button>
              )
            }
            <div>
              <div className={classes.flex}>
                <button
                  type="button"
                  title="jump to time"
                  className={classes.durationBtn}
                  onClick={() => goToTime(phrase.offsetSec)}
                >
                  {formattedDuration} to {formattedDurationEnd}
                </button>
                <button
                  type="button"
                  className={classes.button}
                  onClick={() => {
                    if (!isUpdating) {
                      setShowEditPhrase(!showEditPhrase)
                    }
                  }}
                >
                  <EditIcon className={classes.customIcon}/>
                </button>
                {previousSpeakerName === speaker && (
                  <button
                    type="button"
                    className={classes.button}
                    onClick={() => setShowEdit(!showEdit)}
                    title="Change speaker"
                  >
                    <PersonIcon className={classes.customIcon}/>
                  </button>
                )}
                {saving &&
                  <span className={classes.saving}>
                    <CircularProgress size={'10px'}/> saving..
                  </span>
                }
              </div>
            </div>
          </>
        </div>
        {!showEditPhrase ? (
          <div>
            <div
              onDoubleClick={() => {
                if (!isUpdating) {
                  setShowEditPhrase(true)
                }
              }}
              onClick={() => {
                if (!isUpdating) {
                  setShowEditPhrase(true)
                }
              }}
              className={cn(`rounded-b-lg rounded-tr-lg p-3 bg-gray-100`, {
                "border-2 border-red-700 shadow-lg bg-pink-100":
                  currentTime >= phrase.offsetSec &&
                  currentTime <= phrase.offsetSec + phrase.durationSec,
              })}
            >
              <div className={classes.editedPhrase}>{phrase.display}</div>
            </div>

            <div className="pt-1">
              <span className={classes.confidence}>
                <span
                  className={clsx(classes.confidenceBackground,
                    {
                      [classes.green]: phrase.confidence >= 0.7,
                      [classes.yellow]: phrase.confidence >= 0.5 && phrase.confidence < 0.7,
                      [classes.red]: phrase.confidence < 0.5,
                    }
                  )}
                  title={`Confidence Score: ${phrase.confidence}`}
                >
                  {phrase.confidence?.toFixed(3)}
                </span>
              </span>
              {showSaved && <span className={classes.saved}>Saved!</span>}
              {phrase.editedPhrase && (
                <span className="text-xs font-normal italic text-gray-500 float-right mr-4">
                  Edited{" "}
                  {formatDistanceToNow(
                    addMinutes(
                      phrase.lastPhraseEdited as Date,
                      -1 * new Date().getTimezoneOffset()
                    ),
                    {
                      includeSeconds: true,
                      addSuffix: true,
                    }
                  )}
                  {phrase.lastEditor && <span> by {phrase.lastEditor.fullName}</span>}
                  </span>
              )}
            </div>
          </div>
        ) : (
          <div>
            <EditPhrase
              disabled={saving}
              onChange={(value) => {
                if ((phrase.editedPhrase || phrase.display).trim() !== value) {
                  setSaving(true);
                  onPhraseEdit(TranscriptStatus.DRAFT, mapContent(value, transcriptData.content, position)).then(() => {
                    setSaving(false);
                    setShowEditPhrase(false);
                    setShowSaved(true);
                    setTimeout(() => {
                      setShowSaved(false);
                    }, 2000);
                  });
                } else {
                  setShowEditPhrase(false);
                }
              }}
              initialValue={phrase.editedPhrase || phrase.display}
            />
          </div>
        )}
      </div>
    </Box>
  );
};

export default RecognizedPhrase;
