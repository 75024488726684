/* eslint-disable no-console */
import { action, computed, observable, reaction, runInAction } from "mobx";
import moment, { Moment } from "moment-timezone";
import {
	AttachmentDTO,
	ClassificationClient,
	CreateNoteCommand,
	ICreateNoteCommand,
	IDeclaredLocation,
	InformationManagementMarkerDTO,
	IUpdateNoteCommand,
	NoteClient,
	NoteDTO,
	ProtectiveMarkingDTO,
	SessionViewModel,
	UpdateNoteCommand,
} from "services";
import { RootStore } from "store";
import { GetPlatformSpecificDate, SortAttachmentsByType } from "utils";
import BaseStore from "./baseStore";
import ElectronService from "../services/electron.service";
import mapsService from "../services/maps-service";

const isElectron = ElectronService.isElectron();

const REQUEST_LOCATION_INITIAL_REQUEST = 300000; // Request location after 5 mins

export interface CreateUserInput {
	workGroup: string;
	interfaceTimeZone: string;
	organisationIdentifier: string;
	accessControlGroup: number;
}

export interface EditNoteAdditionalAttachmentInput {
	// Used to mark that this note need to do attachment upload after finishing
	fileBlob: Blob;
	fileName: string;
}

export interface EditNoteInput {
	id?: number;
	title?: string | undefined;
	declaredStart?: Date;
	declaredLocationError?: string;
	declaredLocation: {
		name?: string;
		position?: { lat?: number; lon?: number };
	};
	declaredUrn?: {
		name?: string;
	};
	capturedLocation: { lat?: number; lon?: number };
	body?: string | undefined;
	isPrivate?: boolean;
	protectiveMarkingId?: number;
	informationManagementMarkerId?: number;
	keywords?: string[] | undefined;
	attachments?: AttachmentDTO[] | undefined;
	surpressLocation: boolean;
	created?: Date;
	deviceLocation?: string;
	ownerName?: string;
	ownerEmail?: string;
	ownerId?: string;
	newNoteAttachment?: EditNoteAdditionalAttachmentInput;
	clientCreatedTime?: Date;
	clientUpdatedTime?: Date;
	lastModified?: Date;
}

export class NoteStore extends BaseStore {
	private _noteClient: NoteClient;
	@observable sessionDate: Moment | null = null;
	@observable notes: NoteDTO[] | undefined = [];
	@observable currentMonth: Date | null = null;
	@observable activeIndex: number = -1;
	@observable newNote: boolean = false;
	@observable position = null;
	@observable surpressLocation: boolean = false;
	@observable location: string = "";
	@observable editing: boolean = false;
	@observable saving: boolean = false;
	@observable addedNoteCount: number = 0;
	@observable selectedDays: { [key: string]: number } = {};
	@observable lastLocations: IDeclaredLocation[] = [];
	@observable lastURN: string[] = [];
	@observable protectiveMarkings: ProtectiveMarkingDTO[] = [];
	@observable managementMarkers: InformationManagementMarkerDTO[] = [];

	@observable isBrowserBlockingLocationRequest: boolean = false;
	private _cachedDecodedAddress: { [key: string]: string } = {};

	@computed get activeNote(): NoteDTO | null {
		if (!this.notes) {
			return null;
		}
		if (this.notes.length > 0) {
			return this.notes[this.activeIndex];
		}
		return null;
	}

	constructor(root: RootStore) {
		super(root);
		this._noteClient = new NoteClient(this.API_URL);

		this.init();

		// running scheduled GetPosition
		window.setTimeout(() => {
			this.GetPosition();
		}, REQUEST_LOCATION_INITIAL_REQUEST);
	}

	@action setEditing = (e: boolean) => {
		this.editing = e;
	};

	@action cancelNewNoteCreation = () => {
		this.newNote = false;
		this.editing = false;
	};

	GetPosition = (): Promise<any> => {
		return new Promise<any>((resolve) => {
			if (!navigator.geolocation || isElectron) {
				console.log(
					"Location detection is not supported or turned off"
				);
				resolve(null);
			} else {
				navigator.geolocation.getCurrentPosition(
					(p: any) => {
						console.log("position is", p);
						this.SetPosition({ position: p });
						resolve(p);
					},
					(e) => {
						console.log("GET location error", e);
						this.SetPosition({ isBlockedByBrowser: true });
						resolve(null);
					},
					{
						// https://stackoverflow.com/questions/27156049/navigator-geolocation-getcurrentposition-always-getting-timeout-in-android-until
						// maximumAge: integer (milliseconds) | infinity - maximum cached position age.
						// timeout: integer (milliseconds) - amount of time before the error callback is invoked, if 0 it will never invoke.
						// enableHighAccuracy: false | true
						maximumAge: 60000 * 5, // 5 mins
						timeout: 5000,
						enableHighAccuracy: true,
					}
				);
			}
		});
	};

	init() {
		reaction(
			() => this.sessionDate,
			() => {
				this.GetNotes();
			}
		);
	}

	@action StartSession() {
		this.SetSessionDate(moment());
	}

	async GetClassifications() {
		const client = new ClassificationClient(this.API_URL);
		this.AuthorisedRequest(client, async () => {
			try {
				const response = await client.getProtectiveMarkings();
				const response_2 = await client.getInformationManagementMarkers();

				if (response.protectiveMarkings) {
					this.protectiveMarkings = response.protectiveMarkings;
				}
				if (response_2.informationManagementMarkers) {
					this.managementMarkers =
						response_2.informationManagementMarkers;
				}
			} catch (e) {
				this._rootStore.submitExceptionToAppInsight(e);
			}
		});
	}

	PopulateNoteForm(note: NoteDTO | null): EditNoteInput {
		if (!note) {
			return {} as EditNoteInput;
		}

		const isLocationCaptured = !!(
			note.deviceLatitude && note.deviceLongitude
		);

		return {
			...note,
			ownerId: note.createdBy,
			deviceLocation: note.deviceLocation,
			capturedLocation: {
				lat: note.deviceLatitude,
				lon: note.deviceLongitude,
			},
			surpressLocation: !isLocationCaptured,
			declaredLocation: {
				name: note.declaredLocation,
				position: {
					lat: note?.declaredLatitude,
					lon: note?.declaredLongitude,
				},
			},
			declaredUrn: {
				name: note.urn,
			},
		};
	}

	async decodeAddress(coords: any) {
		if (!coords) return "";

		const key = `${coords.latitude}-${coords.longitude}`;
		// we don't want to keep calling the decode api if user did not move
		// most desktop users don't move
		// just cache the decoded address in memory for now
		let address = this._cachedDecodedAddress[key];

		if (address) return address;

		address = await mapsService.ReverseAddressSearch(
			coords.latitude,
			coords.longitude
		);
		// cache it
		this._cachedDecodedAddress = {
			...this._cachedDecodedAddress,
			[key]: address,
		};
		return address;
	}

	async CreateNewBlankNoteData(): Promise<EditNoteInput> {
		// Grab user data from other store
		const currentUser = this._rootStore.userStore.currentUser;
		const defaultPm = this.protectiveMarkings.find(
			(pm) => pm.isDefault === true
		);

		const newNote = new NoteDTO();
		Object.assign<NoteDTO, Partial<NoteDTO>>(newNote, {
			protectiveMarking: defaultPm,
			protectiveMarkingId: defaultPm?.id,
			declaredStart: new Date(), // BE already assume that this is in UTC time - no need to converting to user's locale
			body: "",
			isPrivate: false,
			ownerName: `${currentUser?.firstName} ${currentUser?.lastName}`,
			ownerEmail: currentUser?.email,
		});

		return this.PopulateNoteForm(newNote);
	}

	@action ChangeSelectedMonth(date: Date, forcedRefresh?: boolean) {
		if (
			date.getFullYear() === this.currentMonth?.getFullYear() &&
			date.getMonth() === this.currentMonth?.getMonth() &&
			!forcedRefresh
		)
			return;

		// only get monthly notes count when month actually changed
		// we call mid of the month
		this.currentMonth = new Date(date.getFullYear(), date.getMonth(), 15);
		this.GetMonthlyNotes(this.currentMonth);
		this.selectedDays = {};
		this.SetAddedNoteCount(0);
	}

	@action async GetMonthlyNotes(date: Date) {
		this.AuthorisedRequest(this._noteClient, async () => {
			try {
				const selectedDays = await this._noteClient.getMonthlyNotes(
					date
				);
				if (selectedDays) {
					this.selectedDays = selectedDays;
					return selectedDays;
				}
			} catch (e) {
				this._rootStore.submitExceptionToAppInsight(e);
			}
		});
	}

	@action async GetLastDeclaredLocations() {
		this.AuthorisedRequest(this._noteClient, async () => {
			try {
				const notesHistory = await this._noteClient.getNotesHistory();
				this.lastLocations = notesHistory.declaredLocations || [];
				this.lastURN = notesHistory.uniqueReferences || [];
			} catch (e) {
				this._rootStore.submitExceptionToAppInsight(e);
			}
		});
	}

	@action async GetNotes(newNoteIdSelection?: number) {
		this.AuthorisedRequest(this._noteClient, async () => {
			this.loading = true;
			try {
				var d = this.sessionDate
					? this.sessionDate.toDate()
					: new Date();
				var USFormat = d.toLocaleDateString("en-Us");
				var dateOnlyString =
					USFormat.split("/")[2] +
					"-" +
					USFormat.split("/")[0] +
					"-" +
					USFormat.split("/")[1];

				const sessionNotes =
					await this._noteClient.getSessionNotesByDate(
						dateOnlyString
					);
				this.loading = false;

				this.SetNotes(sessionNotes, newNoteIdSelection);
			} catch (e) {
				this._rootStore.submitExceptionToAppInsight(e);
				this.loading = false;
				this.error = "Error: could not fetch notes";
			}
		});
	}

	private MapEditToUpdateCommand = async (
		values: EditNoteInput
	): Promise<IUpdateNoteCommand> => {
		return {
			id: values.id,
			body: values.body,
			declaredLatitude: values.declaredLocation?.position?.lat,
			declaredLongitude: values.declaredLocation?.position?.lon,
			declaredLocation: values.declaredLocation?.name,
			title: values.title,
			declaredStart: values.declaredStart,
			isPrivate: values.isPrivate,
			protectiveMarkingId: values.protectiveMarkingId,
			informationManagementMarkerId: values.informationManagementMarkerId,
			keywords: values.keywords,
			// TODO: Remove the following device geolocation data once the update command doesn't need the device data back
			deviceLocation: values.deviceLocation,
			deviceLatitude: values.capturedLocation?.lat,
			deviceLongitude: values.capturedLocation?.lon,
			urn: values.declaredUrn?.name,
		};
	};

	private MapEditToCreateCommand = async (
		values: EditNoteInput
	): Promise<ICreateNoteCommand> => {
		let newNoteCommand: ICreateNoteCommand = {
			body: values.body,
			declaredLatitude: values.declaredLocation?.position?.lat,
			declaredLongitude: values.declaredLocation?.position?.lon,
			declaredLocation: values.declaredLocation?.name,
			title: values.title,
			declaredStart: values.declaredStart,
			isPrivate: values.isPrivate,
			protectiveMarkingId: values.protectiveMarkingId,
			informationManagementMarkerId: values.informationManagementMarkerId,
			keywords: values.keywords,
			urn: values.declaredUrn?.name,
		};

		if (!values.surpressLocation) {
			// Note doesn't have existing location yet, and user want to record the device location
			const devicePosition = await this.GetPosition();
			if (devicePosition) {
				const deviceLocation = await this.decodeAddress(
					devicePosition.coords
				);

				newNoteCommand = {
					...newNoteCommand,
					deviceLocation,
					deviceLatitude: devicePosition.coords.latitude,
					deviceLongitude: devicePosition.coords.longitude,
				};
			}
		}

		return newNoteCommand;
	};

	GetAddress = (value: string): Promise<any[]> => {
		return mapsService.SearchAddress(value);
	};

	@action async SaveNote(values: EditNoteInput) {
		if (this.saving) {
			// Prevent double-saving a note
			return;
		}
		this.loading = true;
		this.saving = true;
		this.AuthorisedRequest(this._noteClient, async () => {
			const isNewNote = this.newNote;

			try {
				let updatedNoteId: number;
				if (isNewNote) {
					const createData = new CreateNoteCommand(
						await this.MapEditToCreateCommand(values)
					);
					updatedNoteId = await this._noteClient.create(createData);

					// Workaround to upload attachment while note has not yet been saved
					if (values.newNoteAttachment?.fileBlob) {
						await this._rootStore.fileStore.uploadFile(
							values.newNoteAttachment.fileBlob,
							values.newNoteAttachment.fileName,
							updatedNoteId
						);
					}
				} else {
					const updateData = new UpdateNoteCommand(
						await this.MapEditToUpdateCommand(values)
					);
					updatedNoteId = await this._noteClient.update(updateData);
				}

				if (updatedNoteId) {
					await this.GetNotes(updatedNoteId);

					// refresh the count of notes in each day because date might have changed
					// or we added new notes
					this.ChangeSelectedMonth(
						this.sessionDate?.toDate() || new Date(),
						isNewNote
					);

					// update search result
					this._rootStore.searchStore.UpdateNoteInSearch(
						updatedNoteId,
						values
					);
				}

				// update the last 5 address so that we don't have to call the backend api
				// the last address is always at the index 0
				const lastAddress = {
					name: values.declaredLocation.name,
					latitude: values.declaredLocation.position?.lat,
					longitude: values.declaredLocation.position?.lon,
				} as IDeclaredLocation;

				this.lastLocations = [lastAddress]
					// add other address
					.concat(
						this.lastLocations.filter(
							(x) => x.name !== lastAddress.name
						)
					)
					// take first 5 only
					.slice(0, 5);

				if (values.declaredUrn?.name) {
					this.lastURN = [values.declaredUrn?.name]
						.concat(
							this.lastURN.filter(
								(x) => x !== values.declaredUrn?.name
							)
						)
						.slice(0, 5);
				}

				// reset state
				this.newNote = false;
				this.success = "Note Saved";
				this.loading = false;
				this.saving = false;
			} catch (e) {
				this._rootStore.submitExceptionToAppInsight(e);
				this.error = "Error: could not save your note";
				this.SetNotes({ notes: this.notes } as SessionViewModel);
				this.loading = false;
				this.saving = false;
			}
		});
	}

	@action async CreateNote(): Promise<EditNoteInput> {
		this.AuthorisedRequest(this._noteClient, async () => {
			runInAction(() => {
				this.newNote = true;
				this.activeIndex = -1;
				this.setEditing(true);
			});
		});

		return await this.CreateNewBlankNoteData();
	}

	@action SetNotes(
		sessionNotes: SessionViewModel,
		newNoteIdSelection?: number
	) {
		if (sessionNotes.notes) {
			// Get the date so we can potentially save notes in an array
			//const date = this.sessionDate? this.sessionDate: GetDateInTimezone(new Date(),userStore.currentUser?.interfaceTimeZone);
			this.notes = sessionNotes.notes.map(
				(n) =>
					({
						...n,
						attachments: SortAttachmentsByType(n.attachments),
						declaredStart: GetPlatformSpecificDate(n.declaredStart),
						created: GetPlatformSpecificDate(n.created),
						clientCreatedTime: !n.clientCreatedTime ? null : GetPlatformSpecificDate(n.clientCreatedTime),
						clientUpdatedTime: !n.clientUpdatedTime ? null : GetPlatformSpecificDate(n.clientUpdatedTime),
						lastModified: GetPlatformSpecificDate(n.lastModified),
					} as NoteDTO)
			);
			if (newNoteIdSelection && sessionNotes.notes?.length > 0) {
				this.activeIndex =
					sessionNotes.notes.findIndex(
						(note) => note.id === newNoteIdSelection
					) || 0;
			}
		}
	}

	@action SetSessionDate(d: Moment) {
		this.sessionDate = d;
	}

	@action SetAddedNoteCount(count: number) {
		this.addedNoteCount = count;
		if (this.addedNoteCount > 0) {
			const date = this.sessionDate?.date;
			this.selectedDays = {
				...this.selectedDays,
				[`${date}`]: this.selectedDays[`${date}`] + 1,
			};
		}
	}

	@action SetActiveIndex(index: number) {
		this.activeIndex = index;
		this.newNote = false;
	}

	@action SetPosition({
		position,
		isBlockedByBrowser,
	}: {
		position?: any;
		isBlockedByBrowser?: boolean;
	}) {
		if (position) {
			this.position = position;
			this.isBrowserBlockingLocationRequest = false;
		}

		this.position = null;
		this.isBrowserBlockingLocationRequest = !!isBlockedByBrowser;
	}

	@action SetSurpressLocation(bool: boolean) {
		this.surpressLocation = bool;
	}
}

export default NoteStore;
