import React, {useRef} from "react";
import Modal from "@material-ui/core/Modal";
import {observer} from "mobx-react-lite";
import {useStore} from "store";
import {Box, Button, makeStyles,} from "@material-ui/core";
import {preProcess} from "../../utils";
import RecognizedPhrase from "../RecognizedPhrase";
import {TranscriptStatus} from "../../services";
import theme from "../../config/theme";
import ConfirmDialog from "./ConfirmDialog";

const useStyles = makeStyles({
  paperContainer: {
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    top: '50%',
    left: '50%',
    position: 'absolute',
    transform: `translate(-50%, -50%)`,
    width: '75%',
    height: '90%',
    display: 'flex',
    flexDirection: 'column',
  },
  boxContainer: {
    width: '100%',
    height: '90%',
    overflowY: 'auto',
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'end',
    marginTop: '10px',
  },
  cancelBtn: {
    marginRight: "16px",
    padding: "0px 32px",
  },
  disabledBtn: {
    backgroundColor: theme.palette.primary.light,
    color: theme.palette.primary.contrastText,
  },
  disabledBtnCancel: {
    color: theme.palette.primary.contrastText,
  },
  audioViewer: {
    width: '100%', 
    marginBottom: 30
  }
});

const TranscribeModal = () => {
  const classes = useStyles();
  const {transcribeStore} = useStore();
  const {transcriptData, attachmentId, blobUrl} = transcribeStore;
  const data = preProcess(transcriptData?.content);
  const [isUpdating, setIsUpdating] = React.useState(false);
  const [isOnEditMode, setOnEditMode] = React.useState(false);
  const [isShowConfirm, setIsShowConfirm] = React.useState(false);
  const mediaFileControlRef = useRef<any>(null);

  const handleUpdateTranscript = async (status: TranscriptStatus, content: string) => {
    if (isUpdating) {
      return;
    }
    setIsUpdating(true);
    await transcribeStore.updateTranscript({
      attachmentId: attachmentId,
      content: content,
      status: status,
    } as any);
    setIsUpdating(false);
  }
  
  const goToTime = (time: number) => {
    mediaFileControlRef.current.currentTime = time;
  };
  
  const handleClose = () => {
    setIsShowConfirm(false);
  }
  
  const handleConfirm = () => {
    setIsUpdating(true);
    setIsShowConfirm(false);
    transcribeStore.updateTranscript({
      attachmentId: attachmentId,
      content: transcriptData.content,
      status: TranscriptStatus.FINALIZED,
    } as any).then(e => {
      setIsUpdating(false);
      transcribeStore.ToggleTranscriptModal();
    });
  }

  return (
    <>
      <ConfirmDialog open={isShowConfirm} title={"Confirm to generate"} content={"This will generate new PDF and Docx, Are you sure?"} okClick={handleConfirm} cancelClick={handleClose} />
      <Modal
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        open={true}
        style={{zIndex: 1}}
      >
        <div className={classes.paperContainer}>
          <h2>Transcription</h2>
          <audio
            ref={mediaFileControlRef}
            controlsList="nodownload"
            className={classes.audioViewer}
            onContextMenu={(e) => e.preventDefault()}
            key={blobUrl}
            controls
          >
            <source src={blobUrl} type="audio/wav"/>
          </audio>
          <div className={classes.boxContainer}>
            <Box>
              {data && data.map((phrase, index) => (
                  <RecognizedPhrase
                    key={"phrase" + index}
                    previousPhrase={!index ? null : data[index - 1]}
                    phrase={phrase}
                    currentTime={0}
                    onPhraseEdit={handleUpdateTranscript}
                    onEditMode={setOnEditMode}
                    isUpdating={isUpdating}
                    position={index}
                    goToTime={goToTime}
                  />
                )
              )}
            </Box>
          </div>
          <div className={classes.buttonContainer}>
            <Button
              variant="outlined"
              color="default"
              className={classes.cancelBtn}
              onClick={() => {
                handleUpdateTranscript(TranscriptStatus.DISCARDED, transcriptData.content);
                transcribeStore.ToggleTranscriptModal();
              }}
              disabled={isUpdating || isOnEditMode}
              classes={{disabled: classes.disabledBtnCancel}}
            >
              Close
            </Button>
            <Button
              variant="contained"
              onClick={() => {
                setIsShowConfirm(true)
              }}
              disabled={isUpdating || isOnEditMode}
              classes={{disabled: classes.disabledBtn}}
            >
              Finalise
            </Button>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default observer(TranscribeModal);
